<template>
  <vue-jitsi-meet
    v-if="meet"
    ref="jitsiRef"
    domain="meet.jit.si"
    :options="jitsiOptions"
    participantLeft="participantLeft"
  ></vue-jitsi-meet>
</template>

<script>
import { core } from '@/config/pluginInit'
import { Capacitor } from '@capacitor/core'
import { JitsiMeet } from '@mycure/vue-jitsi-meet'
import { Browser } from '@capacitor/browser'

export default {
  name: 'VideoConference',
  components: {
    VueJitsiMeet: JitsiMeet
  },
  props: ['theId'],
  computed: {
    jitsiOptions () {
      return {
        height: 600,
        roomName: (this.$props.theId.value === undefined) ? this.$props.theId : this.$props.theId.value,
        noSSL: false,
        userInfo: {
          email: '',
          displayName: ''
        },
        configOverwrite: {
          enableNoisyMicDetection: false
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false
        },
        onload: this.onIFrameLoad
      }
    }
  },
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      meet: false
    }
  },
  mounted () {
    core.index()
    if (this.device !== 'web') {
      this.meet = false
      let meetLink = this.$props.theId
      if (this.$props.theId.value !== undefined) {
        meetLink = this.$props.theId.value
      }
      const openCapacitorSite = async () => {
        await Browser.open({ url: 'https://meet.jit.si/' + meetLink })
      }
      console.log(openCapacitorSite())
    } else {
      this.meet = true
    }
  },
  methods: {
    onIFrameLoad () {
    },
    onParticipantLeft () {
      alert('aaaa')
    }
  }
}
</script>
